export const loadQuestions = async (categories) => {
  let allQuestions = [];

  for (const category of categories) {
    try {
      console.log(`Attempting to load questions for category: ${category}`);
      const normalizedCategory = category.startsWith('framework-framework-') 
        ? category.replace('framework-framework-', 'framework-')
        : category;
        
      const questionsModule = await import(`../questions/${normalizedCategory}.json`);
      const questions = questionsModule.default;

      if (Array.isArray(questions)) {
        console.log(`Successfully loaded ${questions.length} questions for category: ${normalizedCategory}`);
        const questionsWithCategory = questions.map(question => ({
          ...question,
          category: normalizedCategory
        }));
        allQuestions = [...allQuestions, ...questionsWithCategory];
      } else {
        console.error(`Invalid question format for category: ${normalizedCategory}`);
      }
    } catch (error) {
      console.error(`Error loading questions for category: ${category}`, error);
    }
  }

  console.log(`Total questions loaded: ${allQuestions.length}`);
  return allQuestions;
};
