const validateDependencies = (question, answers) => {
  if (!question.dependsOn) return true;
  
  return question.dependsOn.every(condition => {
    const answer = answers[condition.questionId];
    
    switch (condition.type) {
      case 'equals':
        return answer === condition.value;
      case 'contains':
        return Array.isArray(answer) && answer.includes(condition.value);
      case 'sector':
        return answers.orgSector === condition.value;
      case 'size':
        return answers.orgSize === condition.value;
      default:
        return true;
    }
  });
};

export const getNextRelevantQuestion = (questions, answers, currentIndex) => {
  for (let i = currentIndex + 1; i < questions.length; i++) {
    if (validateDependencies(questions[i], answers)) {
      return i;
    }
  }
  return -1;
};

export const calculateTotalRelevantQuestions = (questions, answers) => {
  return questions.filter(q => validateDependencies(q, answers)).length;
}; 