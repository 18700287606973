import React from 'react';
import { Button } from './ui/button';
import translations from '../translations';                                     

const LanguageSelector = ({ language, setLanguage }) => {
  return (
    <div className="flex justify-center space-x-4 mb-6">
      <Button
        onClick={() => setLanguage('en')}
        className={`flex items-center ${language === 'en' ? 'bg-blue-500' : 'bg-gray-200'}`}
      >
        <img src="/images/usa-flag.png" alt="USA Flag" className="w-6 h-4 mr-2" />
        English
      </Button>
      <Button
        onClick={() => setLanguage('es')}
        className={`flex items-center ${language === 'es' ? 'bg-blue-500' : 'bg-gray-200'}`}
      >
        <img src="/images/spain-flag.png" alt="Spanish Flag" className="w-6 h-4 mr-2" />
        Español
      </Button>
    </div>
  );
};

export default LanguageSelector;
